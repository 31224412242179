import React from "react"

import Layout from "../../components/layout"
import Varnish from "../../components/screens/Skill/Varnish"
import { Helmet } from "react-helmet"

const VarnishPage = () => (
  <Layout pageInfo={{ pageName: "Varnish" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Varnish</title>
        <meta name="description" content="Varnish" />
    </Helmet>
    <Varnish />
  </Layout>
)

export default VarnishPage
